lib-shared-feature-calendar-button {
  display: inline-block;
  position: relative;
}

lib-shared-feature-calendar-button .p-button {
  position: relative;
  overflow: visible;
}

lib-shared-feature-calendar-button button:is(:hover, :focus)::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 8px;
}

lib-shared-feature-calendar-button button:is(.down):is(:hover, :focus)::after {
  bottom: -8px;
}

lib-shared-feature-calendar-button button:is(:hover, :focus) p-menu {
  display: block;
}

lib-shared-feature-calendar-button p-menu {
  display: none;
  position: absolute;
  bottom: calc(100% + 8px);
}

lib-shared-feature-calendar-button .p-menu {
  overflow: hidden;
}

lib-shared-feature-calendar-button button:is(.down) p-menu {
  top: calc(100% + 8px);
  bottom: initial;
}

lib-shared-feature-calendar-button p-menu .p-menuitem a {
  display: block;
  width: 100%;
  padding: 8px 16px;
  color: var(--colorText, black);
}
