.sidemenu-trigger {
  font-size: var(--sidemenu-icon__fontSize, 28px);
}

.p-sidebar-header {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.p-sidebar-close {
  color: var(--close-icon__color);
}

.p-sidebar-close .p-icon {
  width: var(--close-icon__fontSize);
  height: var(--close-icon__fontSize);
}

.p-sidebar-content {
  padding: 0;
}
